import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import background from '../images/bck_404.svg'



// markup
const NotFoundPage:React.FunctionComponent = () => {
  return (
    <>
      <div className="relative overflow-hidden h-screen" style={{background:`url(${background})`}}>
        <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center">
          <StaticImage  src="../images/logo.svg" alt="contact us" className="transform scale-150 filter grayscale"  placeholder="blurred" quality={100}/>
        </div>
        
          <div className="inset-0 bg-black opacity-25 absolute">
          </div>
          <div className="container mx-auto px-6 md:px-12 relative z-10 flex items-center py-32 justify-center">
              <div className="w-full font-mono flex flex-col items-center justify-center relative z-10">
                  <h1 className="font-extrabold text-5xl text-center text-white leading-tight mt-4">
                      Sorry, Page not found.
                  </h1>
                  <p className="font-extrabold text-8xl text-white">
                      404
                  </p>
              </div>
          </div>
      </div>
    </>
  )
}

export default NotFoundPage
